import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import ForgotPasswordComponent from "./ForgotPasswordComponent";

import "../../../pages/signin/styles/signin.css";
import { signinAction } from "../actions/signinActions";

import Footer from "../../../common/components/Footer.js";
import { toast } from "react-toastify";
import AlertDialogSlide from "../../../common/components/AlertDialog";
import ResetPassword from "./ResetPassword";

class SigninComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      showPassword: false,
      openForgotPassword: false,
      userNameErrorText: "",
      passwordErrorText: "",
      openDialog: false,
      openResetPassword: false
    };

    this.onUserNameChange = this.onUserNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.signin = this.signin.bind(this);

    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
  }

  onUserNameChange(event) {
    this.setState({
      ...this.state,
      username: event.target.value,
      userNameErrorText: ""
    });
  }

  onPasswordChange(event) {
    this.setState({
      ...this.state,
      password: event.target.value,
      passwordErrorText: ""
    });
  }

  validateInput() {
    let userNameErrorText = "";
    let passwordErrorText = "";
    let isValid = true;
    if (this.state.username.length === 0) {
      userNameErrorText = "Enter valid user name";
      isValid = false;
    }
    if (this.state.password.length === 0) {
      passwordErrorText = "Enter valid password";
      isValid = false;
    }
    this.setState({ ...this.state, userNameErrorText, passwordErrorText });
    return isValid;
  }

  signin() {
    if (!this.validateInput()) {
      toast("Error: Empty Input values not allowed", {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
    const apiInput = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.signinAction(
      apiInput,
      successResponse => {
        this.props.history.push("/dashboard");
      },
      errorResponse => {
        let errorMessage = errorResponse.status ?
          "Error: Invalid username or password." :
          "Network Error: unable to reach server"
        toast(errorMessage, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        })
        ;
      }
    );
  }

  handleClickShowPassword = () => {
    this.setState({
      type: this.state.type === "password" ? "text" : "password"
    });
  };

  handleForgotPasswordOpen = () => {
    this.handleCloseDialog();
    this.setState({
      openForgotPassword: true
    });
  };

  handleForgotPasswordClose = () => {
    this.setState({
      openForgotPassword: false
    });
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true })
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false })
  }

  handleOpenResetPassword = () => {
    this.handleCloseDialog();
    this.setState({
      openResetPassword: true
     })
  }

  handleCloseResetPassword = () => {
    this.setState({ openResetPassword: false })
  }

  render() {

    const logoFilename = (process.env.REACT_APP_LOGO_FILENAME || "brand_logo.png");

    if (localStorage.getItem("username")) window.location.href = "/dashboard";
    return (
      <div>
      <div>
        <Grid container spacing={24}>
          <Grid item xs={1} md={4}></Grid>

          <Grid item xs={10} md={4} className="loginbox">
            <Grid className="loginboxdiv">
              <Avatar
                alt="Remy Sharp"
                src={logoFilename}
                className="logoimg"
              />
            </Grid>

            <div>
              <TextField
                className="formcontrol"
                label="Username"
                type="text"
                style={{ marginBottom: 8 }}
                margin="normal"
                variant="standard"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={this.onUserNameChange}
              />
            </div>

            <div>
              <TextField
                className="formcontrol"
                label="Password"
                style={{ marginBottom: 8 }}
                margin="normal"
                variant="standard"
                InputLabelProps={{
                  shrink: true
                }}
                type={this.state.type === "password" ? "text" : "password"}
                value={this.state.password}
                onChange={this.onPasswordChange}
                onKeyUp={ e => e.keyCode === 13 ? this.signin() : null }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {this.state.type === "password" ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>

            <div>
              <Button
                variant="outlined"
                className="loginbt"
                onClick={this.signin}
              >
                <Icon> forward </Icon> &nbsp; LogIn
              </Button>
            </div>

              <AlertDialogSlide
                open={this.state.openDialog}
                handleClose={this.handleCloseDialog}
                action={this.handleOpenResetPassword}
                onDeclineAction={this.handleForgotPasswordOpen}
                title={"Password Reset already requested?"}
                desc={"Password Reset Token received?"}
              />

              <ForgotPasswordComponent
                open={this.state.openForgotPassword}
                handleClose={this.handleForgotPasswordClose}
              />

            {this.state.openResetPassword ?
              <ResetPassword
                open={this.state.openResetPassword}
                handleClose={this.handleCloseResetPassword}
              />
            : null}

            <div id="forgot-password-button">
              <button onClick={this.handleOpenDialog}>
                Forgot/Reset Password ?
              </button>
            </div>
          </Grid>

          <Grid item xs={1} md={4}></Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={24}>
        <div className="footer-signin">
        <div >
          {" "}
          <Footer className="footer-signin" />{" "}
        </div>
         </div>
          </Grid>
      </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signinAction
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(SigninComponent);
