import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import DatePicker from "react-datepicker";
import Options from "../behaviors/Options";
import ChartComponent from "./ChartComponent";
import getChartData from "../actions/getChartData";
import { getHistoricalDeviceStatistics } from "../../brhdevice/actions/brhdeviceActions";
import LoaderRequest from "../../../common/components/LoaderRequest";
import { API_URL } from "../../../utils/constants"

import { toast } from 'react-toastify';

class HistoricalPlot extends React.Component {
  constructor(props) {
    super(props);
		var selectedFields = [
      { value: "dvbs2_rtp_seq_error_count", label: 'RTP Sequence Error Count' }
    ];

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      historicalDeviceStats: [],
      chartData: [],
      pageData: [],
      disabledNext: true,
      disabledPrevious: true,
      selectedFields: selectedFields,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setPlotDates();
    }, 2 * 100);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.action === "FETCH_HISTORICAL_DEVICESTATS_GET_DATA_SUCCESS") {
      const historicalDeviceStats = nextProps.historicalInfo;
      this.setState(
        {
          historicalDeviceStats: historicalDeviceStats
        },
        () => {
          this.plotChart();
        }
      );
    }
  }

  timedOutCallback = (error) => {
    if (error.code === "ECONNABORTED") {
      let message = "Error Downloading Historical Data. Request Timed Out.";
        toast(message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER
        });
    }
  }

  getStats = () => {
    this.props.getHistoricalDeviceStatistics(
      this.props.selectedDevice[0].provisioned_device.mac_address,
      this.state.startDate.toISOString(),
      this.state.endDate.toISOString(),
      null, // successCallback
      this.timedOutCallback, // errorCallback
      true,
    );
  };

  plotChart = () => {
    let chartData = [];
    if (this.state.selectedFields && this.state.selectedFields.length > 0) {
      let data = getChartData({
        deviceStats: this.state.historicalDeviceStats,
        selectedFields: this.state.selectedFields
      });
      if (data) {
        chartData = data[0];
      }
    }
    this.setState(
      {
        chartData: chartData,
        pageIndex: 0
      },
      () => {
        let page = Math.floor(
          (this.state.historicalDeviceStats.length - 1) / 20
        );
        this.getPageData(page);
      }
    );
  };

  handleFromDateChange = date => {
    if (date && date < new Date()) {
      this.setState({ ...this.state, startDate: date }, () => {
        this.getStats();
      });
    }
  };
  handleToDateChange = date => {
    if (date && date < new Date() && date >= this.state.startDate) {
      this.setState({ ...this.state, endDate: date }, () => {
        this.getStats();
      });
    }
  };

  getSelectedFields = selectedFields => {
    if (selectedFields) {
      selectedFields = selectedFields.slice(0, 4);
    }
    this.setState(
      {
        selectedFields: selectedFields
      },
      () => {
        this.getStats();
      }
    );
  };

  setPlotDates = (days = 7) => {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - days);
    // this.handleFromDateChange(startDate);
    this.setState({ startDate: startDate, endDate: new Date() }, () => {
      this.getStats();
    });
  };

  getPageData = page => {
    let byPoints = 20;
    let pageIndex = this.state.pageIndex + page;
    let pointIndex = pageIndex * byPoints;
    let data = this.state.chartData.slice(1);

    if (pointIndex <= data.length) {
      if (pointIndex >= 0) {
        let pageData = data.slice(pointIndex, pointIndex + byPoints);
        pageData.splice(0, 0, this.state.chartData[0]);
        this.setState(
          {
            pageData: pageData,
            pageIndex: pageIndex,
            disabledNext: false,
            disabledPrevious: false
          },
          () => {
            this.getTicks(pageData.slice(1));
          }
        );
      }
      if (pageIndex === 0) {
        this.setState({ disabledPrevious: true });
      }
    }
    if (pointIndex + byPoints >= this.state.chartData.length - 1) {
      this.setState({ disabledNext: true });
    }
  };

  handleDownload = (startDate, endDate, mac_address) => {

    toast.info(`Downloading Historical Data`, { autoClose: 5000 });
    const url = `${API_URL}/frontend/devices/${mac_address}/stats/?stats_after=${startDate}&stats_before=${endDate}&csv=1`;

    // Open CSV download directly in a new tab
    window.open(url, '_blank');
  };

  getTicks = pageData => {
    let ticks = [];
    for (let i = 0; i < pageData.length; i += 6) {
      ticks.push(pageData[i][0]);
    }
    if (
      pageData.length > 0 &&
      !ticks.includes(pageData[pageData.length - 1][0])
    ) {
      ticks.push(pageData[pageData.length - 1][0]);
    }
    this.setState({
      ticks: ticks
    });
  };

  render() {
    return (
      <div>
        <h2 style={{ textAlign: "center" }}>Historical Data</h2>
        <div className="col-sm-12 drop-margin">
          <Options
            getSelected={this.getSelectedFields}
            defaultValue={this.props.defaultValue}
            deviceSource={this.props.deviceSource}
          ></Options>
        </div>
        <br />
        <div className="btn-contain">
          <div className="filter-dataleft">
            <DatePicker
              className="form-control filterdate-plotchar"
              selected={this.state.startDate}
              placeholderText="Plot From Date"
              onChange={this.handleFromDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={7}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeCaption="time"
            />
            <DatePicker
              className="form-control filterdate-plotchar"
              selected={this.state.endDate}
              placeholderText="Plot Till Date"
              onChange={this.handleToDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={7}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeCaption="time"
            />
            <Button
              variant="contained"
              className="filterdate-plotchar"
              color="primary"
              onClick={this.getStats}
            >
              Plot Chart
            </Button>
            <Button
              className="filterdate-plotchar"
              variant="contained"
              color="primary"
              onClick={() => this.setPlotDates(1)}
            >
              last 1 Day
            </Button>
            <Button
              className="filterdate-plotchar"
              variant="contained"
              color="primary"
              onClick={() => this.setPlotDates(7)}
            >
              last 7 Days
            </Button>
            <Button
              className="filterdate-plotchar"
              variant="contained"
              color="primary"
              onClick={() => this.setPlotDates(15)}
            >
              last 15 Days
            </Button>
          </div>
      <LoaderRequest />
          {/* <div className="filter-dataright"> */}
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => this.handleDownload(this.state.startDate.toISOString(), this.state.endDate.toISOString(), this.props.selectedDevice[0].provisioned_device.mac_address)}
          >
            Download Historical CSV <span className="glyphicon glyphicon-download-alt"></span>
          </button>
          {/* </div> */}
          {this.state.disabledPrevious ? null : (
            <Button
              style={{ float: "left" }}
              variant="contained"
              color="secondary"
              disabled={this.state.disabledPrevious}
              onClick={() => this.getPageData(-1)}
              className="leftChart-btn"
            >
              <div className="icon">
                <i className="fa fa-chevron-left" />
              </div>
            </Button>
          )}
          {this.state.disabledNext ? null : (
            <Button
              style={{ float: "right" }}
              variant="contained"
              color="secondary"
              className="righthart-btn"
              disabled={this.state.disabledNext}
              onClick={() => this.getPageData(1)}
            >
              <div className="icon">
                <i className="fa fa-chevron-right" />
              </div>
            </Button>
          )}
        </div>
        {this.state.chartData.length > 0 ? (
          <ChartComponent
            ticks={this.state.ticks}
            chartData={this.state.pageData}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

// //Required to tell redux how this component is mapped with dashboard reducers
function mapStateToProps(state) {
  return {
    historicalInfo: state.brhDevicesInfo.historicalInfo,
    action: state.brhDevicesInfo.action
  };
}

//Required to tell redux this component using which actions
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getHistoricalDeviceStatistics
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricalPlot);
