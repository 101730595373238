import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchBRHDevicesCount, fetchAlarmsCount, LicenseAccepted } from '../actions/MenuActions'
//import {fetchBRHDevicesCount} from '../actions/MenuActions'

class Menu extends Component {

  componentDidMount() {
    if (localStorage.getItem("eulaAccepted") !== null) {
      const eulaAccepted = JSON.parse(localStorage.getItem("eulaAccepted"));
      this.props.dispatch({
        type: "USER_ACCEPTED_EULA",
        payload: eulaAccepted
      });
    }
    this.props.fetchBRHDevicesCount();
    this.props.fetchAlarmsCount();
    setInterval(() => {
      this.props.fetchBRHDevicesCount();
      this.props.fetchAlarmsCount();
    }, 30 * 1000);
  }

  render() {
    if (this.props.eulaAccepted) {
      return this.renderLicAccepted();
    } else {
      return this.renderLicNotAccepted();
    }
  }

  renderLicNotAccepted() {
    return (
      <div>
        <aside className="main-sidebar">
          <section className="sidebar">
            <ul className="sidebar-menu" data-widget="tree">
              <li className={(this.props.dashboardSidebar.activeButton === 'dashboard') ? 'active' : ''}>
                <a href="#license">
                  <span>Tejas Networks: Yoga - EULA</span>
                </a>
              </li>
            </ul>
          </section>
        </aside>
      </div>
    )
  }

  renderLicAccepted() {
    return (
      <div>
        <aside className="main-sidebar">
          <section className="sidebar">
            <ul className="sidebar-menu" data-widget="tree">
              <li className={(this.props.dashboardSidebar.activeButton === 'dashboard') ? 'active' : ''}>
                <a href="/dashboard">
                  <i className="fa fa-home" /> <span>Dashboard</span>
                </a>
              </li>
              <li className={(this.props.dashboardSidebar.activeButton === 'brhdevices') ? 'active' : ''}>
                <a href="/brhdevices" title="Running Devices Information and Statistics">
                  <i className="fa fa-laptop" /> <span>BRH Devices</span>
                  <span style={{ float: 'right' }}>
                    {(this.props.activeDevicesCount === null) ? null : (this.props.activeDevicesCount + '/' +
                      this.props.totalDevicesCount)}
                  </span>
                </a>
              </li>
              <li className={(this.props.dashboardSidebar.activeButton === 'notifications') ? 'active' : ''}>
                <a href="/notifications" title="Alarms generated at Devices">
                  <i className="fa fa-bell-o" /> <span>Alarms</span>
                  <span style={{ float: 'right' }}>
                    {(this.props.totalAlarmsCount === null) ? null : this.props.totalAlarmsCount}
                  </span>
                </a>
              </li>
              <li className="nav-item treeview actives menu-open" >
                <a href="/configurations" className="submenu-main sideMenu-open">
                  <i className="fa fa-cog"></i> <span>Configurations</span>
                </a>
                <ul className="treeview-menu">
                  {localStorage.getItem('roleKey') === "operator" ? "" : (
                    <li className={(this.props.dashboardSidebar.submenu === 'devices') ? 'active ' : ''} >
                      <a href="/configurations" title="Device Boot Time Configurations.">
                        <i className="fa fa-caret-right" /> <span>Devices</span>
                      </a>
                    </li>
                  )}
                  <li className={(this.props.dashboardSidebar.submenu === 'alarms') ? 'active' : ''}>
                    <a href="/alarms" title="Device Alarm Configurations (Change Severity/AutoAck etc.)"><i className="fa fa-caret-right"></i> Alarms</a>
                  </li>
                </ul>
              </li>

              {localStorage.getItem('roleKey') === "operator" ? "" : (
                <li className="nav-item treeview menu-open actives">
                  <a href="/admins" className="submenu-main sideMenu-open" >
                    <i className="fa fa-history"></i> <span>Provisioning</span>
                  </a>
                  <ul className="treeview-menu">
                    <li className={(this.props.dashboardSidebar.submenu === 'admins') ? 'active' : ''}>
                      <a href="/admins" title="Details of Users belonging to Admin role."><i className="fa fa-caret-right"></i> Admins</a>
                    </li>
                    <li className={(this.props.dashboardSidebar.submenu === 'operators') ? 'active' : ''}>
                      <a href="/operators" title="Details of Users belonging to Operator role."><i className="fa fa-caret-right"></i> Operators</a>
                    </li>
                    <li className={(this.props.dashboardSidebar.submenu === 'device') ? 'active' : ''}>
                      <a href="/devices" title="Device Provisioning Information, Sl. No etc."><i className="fa fa-caret-right"></i> Devices</a>
                    </li>
                  </ul>
                </li>
              )}

              {localStorage.getItem('roleKey') === "operator" ? "" : (
                <li className={(this.props.dashboardSidebar.activeButton === 'auditlogs') ? 'active' : ''}>
                  <a href="/auditlogs" title="Audit Log of actions taken by users and events generated at devices.">
                    <i className="fa fa-exclamation-circle" /> <span>Audit Logs</span>
                  </a>
                </li>
              )}

              <li className={(this.props.dashboardSidebar.activeButton === 'brhfirmwares') ? 'active' : ''}>
                <a href="/brhfirmwares" title="Manage Device Firmwares.">
                  <i className="fa fa-upload" /> <span>BRH Firmwares</span>
                </a>
              </li>
            </ul>
          </section>
        </aside>


      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    dashboardSidebar: state.dashboardSidebar.dashboardSidebar,
    activeDevicesCount: state.menuInfo.activeDevicesCount,
    totalDevicesCount: state.menuInfo.totalDevicesCount,
    totalAlarmsCount: state.menuInfo.totalAlarmsCount,
    eulaAccepted: state.menuInfo.isEULAAccepted,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchBRHDevicesCount,
        fetchAlarmsCount,
        LicenseAccepted
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
