import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import "react-datepicker/dist/react-datepicker.css";
import { Chart } from "react-google-charts";
import { toast } from 'react-toastify';

import Options from '../behaviors/Options'
import getNormalizedChartData from '../actions/Normalizer';
import HistoricalPlot from './HistoricalPlot'


import SearchComponent from '../../../common/components/searchComponent';
import ListSelectorDialog from '../../../common/components/ListSelectorDialog';

import ContentWrapper from '../../../common/components/ContentWrapper';
import {
  transmitFrequencies6M,
  transmitFrequencies5w,
  BRHModels,
  transmitFrequencies8M,
  RfPowerLevels,
  paControl,
  RfPowerLevels5w
} from '../../../common/constants/config_params';
import {
  changeSidebarButtonState
} from '../../dashboard/actions/dashboardSidebarActions';

import {
  doRuntimeConfigUpdate,
  callShutdownAPI,
  requestSnapshotAPI,
  callRebootAPI,
  fetchBRHDevicesData,
  getDeviceStatistics,
} from '../../brhdevice/actions/brhdeviceActions';

import { getConfigurationData } from '../../configurations/actions/configurationsActions';

import { getStatus } from '../../../../src/common/constants/constants';
import Modal from './UI/Modal'


import { searchAPICall, isValidInput } from '../actions/searchAction';
import SimpleExpansionPanel from './UI/ExpansionPanel';
import AlertDialogSlide from "../../../common/components/AlertDialog";
import AppBarComponent from "../../../common/components/AppBarComponent";
import Snapshots from './UI/Snapshots';
import { TableContainer, Typography, CardContent, Card } from '@material-ui/core';
import UpgradeModal from './UI/UpgradeModal';
import GeolocationModal from "./UI/GeolocationModal"
import DeviceConfiguration from './UI/DeviceConfiguration';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}


class BRHDeviceComponent extends Component {
  constructor(props) {
    super(props);
    var defaultSelectedValues = [
      { value: "dvbs2_rtp_seq_error_count", label: 'RTP Sequence Error Count' }
    ];
    this.state = {
      selectedValues: defaultSelectedValues,
      selectedKeys: [],
      selectHelperText: <h5 style={{ color: 'blue' }}>Please Select Options to Plot Chart </h5>,
      deviceStats: [],
      openGeolocationModal: false,
      statisticsInfo: [],
      graphData: [],
      openRuntimeConf: false,
      openGetSnapshot: false,
      openShowSnapshots: false,
      openDeviceConf: false,
      openAlert: false,
      deviceId: null,
      requestShutdown: false,
      TXFreq: 533.0,
      TXFreq8m: 474.0,
      TXPower: 0,
      TXPaControl: 1,
      openGeoPopUp: false,
      openGeoPopUpSingle: false,
      checked: false,
      startDate: null,
      endDate: null,
      selected: null,
      statsTimer: null,
      moreBtn: false,
      defaultValue: defaultSelectedValues,
      filterURL: null,
      requested_at: null,
      note: '',
      swap: false,
      configurationsInfo: [],

      // state related to firmware Upgrade
      openUpgradeSingle: false,
      openUpgradeMany: false,
      isModelSelectorDialogOpen: false,
      selectedModelForFwUpgrade: BRHModels[1],
    }

    this.getContentView = this.getContentView.bind(this);
    this.showMoreHandler = this.showMoreHandler.bind(this);
    this.hidehMoreHandler = this.hidehMoreHandler.bind(this);
    this.closeMorehide = this.closeMorehide.bind(this);
    this.handleOpenGeolocationModal = this.handleOpenGeolocationModal.bind(this);


    this.getDeviceStatisticsOnRowClick = this.getDeviceStatisticsOnRowClick.bind(this);
    this.plotSelected = this.plotSelected.bind(this);
    this.selectedValuesChanged = this.selectedValuesChanged.bind(this);
    this.drawChart = this.drawChart.bind(this);
    this.handleOpenRuntimeConf = this.handleOpenRuntimeConf.bind(this);
    this.handleOpenDeviceConf = this.handleOpenDeviceConf.bind(this);
  }

  intervalID = 0;

  // During Bulk Upgrade, A Dialog showing a list of Models to chose from is
  // presented. When that Dialog is closed, only devices for that model
  // and active state are presented for Firmware upgrade.
  // Next Upgrade Dialog is opened for Many Devices.
  closeModelSelectorDialogForFWUpgrade = (value) => {
    this.setState({
      ...this.state,
      selectedModelForFwUpgrade: value,
      isModelSelectorDialogOpen: false,
      openUpgradeMany: true
    });
  }

  selectedValuesChanged(vals) {
    this.setState({
      ...this.state,
      selectedValues: vals
    }, () =>
      this.plotSelected());
  }

  plotSelected() {
    let selectValues = this.state.selectedValues;
    if (this.state.deviceStats.length > 0) {

      let selectedKeys = ['timestamp'];
      let selectedLabels = ['Timestamp'];

      if (selectValues && 0 < selectValues.length < 5) {
        selectValues.forEach(selected => {
          selectedKeys.push(selected['value']);
          selectedLabels.push(selected['label']);

        });
      }

      let selectHelperText = (selectValues === null || selectValues.length < 1) ?
        <h5 style={{ color: 'blue' }}>Please Select Options to Plot Chart </h5> : null;

      this.setState(
        {
          selectedKeys: selectedKeys,
          selectHelperText: selectHelperText,
          selectedLabels: selectedLabels
        },
        () => {
          this.drawChart();
        }
      );
    }
    else {
      let selectHelperText = <h5 style={{ color: 'blue' }}>Data not Available for Plotting</h5>
      this.setState(
        {
          ...this.state,
          selectHelperText,
          graphData: [],
          lastDeviceStats: null
        }
      );
    }
  }

  componentDidMount() {
    this.props.changeSidebarButtonState('brhdevices', 'BRHDEVICES_BTN_ACTIVE');
    this.props.fetchBRHDevicesData();
    const user_id = localStorage.getItem("user_id");
    const user_name = localStorage.getItem("username");
    const url = "frontend/devices/?user_id=" + user_id + "&user_name=" + user_name
    setInterval(async () => {
      this.props.fetchBRHDevicesData(this.state.filterURL ? this.state.filterURL : url);
    }, (60 * 1000));
    setTimeout(() => {
      this.plotSelected();
    }, (2 * 1000));
  }

  drawChart() {

    //let timeFrame = this.state.deviceStats.length;
    let graphData = [];
    let lastDeviceStats = this.state.deviceStats[this.state.deviceStats.length - 1];
    let graphDataPoints = [];

    for (let i = 0; i < this.state.deviceStats.length; i++) {


      graphDataPoints = [];
      let yAxisValues = [];

      if (i === 0) {
        graphData.push(this.state.selectedLabels);
      }

      this.state.selectedKeys.forEach(key => {

        var deviceStat = this.state.deviceStats[i];
        let value = deviceStat[key];

        if (key === "timestamp") {
          let utcTimestamp = new Date(value);
          value = utcTimestamp.toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
          });
        }

        yAxisValues.push(value);
      })

      graphDataPoints = graphDataPoints.concat(yAxisValues);
      graphData.push(graphDataPoints);
    }

    let normalizedChartData = getNormalizedChartData(graphData);
    normalizedChartData.splice(0, 0, this.state.selectedLabels)
    let i = 0
    for (let arr of normalizedChartData.slice(1)) {
      arr.splice(0, 0, (this.state.deviceStats[i]['timestamp']))
      i++;
    }

    this.setState({
      ...this.state,
      graphData: normalizedChartData,
      lastDeviceStats
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.action === 'FETCH_DEVICESTATS_GET_DATA_SUCCESS') {
      const devicestats = nextProps.statisticsInfo;
      this.setState({ deviceStats: devicestats })
    }
  }

  componentWillUnmount() {
    if (this.state.statsTimer) {
      clearInterval(this.state.statsTimer);
    }
  }


  requestDeviceRebootShutdown = () => {
    let device_selected = this.props.brhDevicesInfo.filter(device => device.id === this.state.selected)
    let deviceMacId = device_selected[0].provisioned_device.mac_address;
    if (this.state.requestShutdown) {
      const data = this.state.note ? { note: this.state.note } : {};
      callShutdownAPI(deviceMacId, data, (data) => {
        toast("Device Shutdown Requested.", {
          autoClose: 5000,
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        this.closeRebootAlert();
      }, (error) => {
        toast("Device Shutdown Request Failed.", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
    } else {
      const data = this.state.note ? { note: this.state.note, swap: this.state.swap } : { swap: this.state.swap };
      callRebootAPI(deviceMacId, data, (data) => {
        toast("Device Reboot Requested.", {
          autoClose: 5000,
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        this.closeRebootAlert();
      },
        (error) => {
          toast("Device Reboot Request Failed.", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
  }

  requestSnapshot = () => {
    let device_selected = this.props.brhDevicesInfo.filter(device => device.id === this.state.selected)
    let deviceMacId = device_selected[0].provisioned_device.mac_address;
    const data = this.state.note ? { note: this.state.note } : {};
    requestSnapshotAPI(deviceMacId, data, () => {
      toast("Device Snapshot Requested.", {
        autoClose: 5000,
        type: toast.TYPE.INFO,
        position: toast.POSITION.TOP_CENTER,
      });
      this.handleCloseGetSnapshot();
    }, (error) => {
      toast("Device Snapshot Request Failed.", {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    });
  }

  handleOpenGeolocationModal(single = false) {
    if (!single) {
      this.setState({ openGeoPopUp: true })
    } else {
      this.setState({ openGeoPopUpSingle: true })
    }
  }

  handleOpenUpgradeMultiple = () => {
    this.setState({ isModelSelectorDialogOpen: true });
  }

  handleOpenUpgradeSingle = (id) => {
    localStorage.setItem('deviceId', id);
    this.setState({ openUpgradeSingle: true });
  };

  getDeviceStatisticsOnRowClick(deviceId) {
    let device_selected = (this.props.brhDevicesInfo).filter(device => device.id === deviceId)
    let deviceMacId = device_selected[0].provisioned_device.mac_address;
    var prevSelected = this.state.selected;
    var statsTimer;
    if (prevSelected !== deviceId) {

      statsTimer = this.state.statsTimer;

      if (statsTimer !== null) {
        clearInterval(statsTimer);
      }

      this.props.getDeviceStatistics(deviceMacId, this.plotSelected);
      statsTimer = setInterval(async () => {
        this.props.getDeviceStatistics(deviceMacId, this.plotSelected);
      }, (60 * 1000));

      // Need to set state for Tx Frequency etc.
      var tx_freq = this.state.TXFreq;
      var tx_freq_8m = this.state.TXFreq8m;
      var tx_power = this.state.TXPower;
      var tx_pa_control = this.state.TXPaControl;

      var d = this.props.brhDevicesInfo.find(row => {
        return row.id === deviceId
      });

      // Get the Config Info for the device: This will be used during
      // Runtime Config 8M/6M
      this.props.getConfigurationData(parseInt(d.provisioned_device.config));

      if (d && d.device_state === 3) {
        tx_freq = d.device_atsc3_tx_frequency;
        tx_freq_8m = d.device_atsc3_tx_frequency_8m;
        tx_power = d.device_atsc3_tx_rf_power_level;
        tx_pa_control = d.device_requested_pa_control ? 1 : 0;
      }

      this.setState({
        selected: deviceId,
        statsTimer: statsTimer,
        TXPower: tx_power,
        TXFreq: tx_freq,
        TXFreq8m: tx_freq_8m,
        TXPaControl: tx_pa_control
      });
    }

  }


  handleClose = () => {
    this.setState({
      openUpgradeSingle: false,
      openUpgradeMany: false,
      showEditOwner: false,
      openRuntimeConf: false,
      openGeoPopUp: false,
      openGeoPopUpSingle: false,
      note: "",
      requested_at: null,
    });
  };

  handleCloseRuntimeConf = (selectedDevice) => {
    var tx_freq;
    var tx_freq_8m;
    var selected_device_configuration = this.props.configurationsInfo;
    if (selectedDevice.device_model_version >= "1.5" &&
      selected_device_configuration.transition_frequency === true) {
      tx_freq_8m = selectedDevice.device_atsc3_tx_frequency_8m;
    } else {
      tx_freq = selectedDevice.device_atsc3_tx_frequency;
    }
    this.setState({
      ...this.state,
      openRuntimeConf: false,
      TXPaControl: selectedDevice.device_requested_pa_control ? 1 : 0,
      TXFreq: tx_freq,
      TXFreq8m: tx_freq_8m,
      TXPower: selectedDevice.device_atsc3_tx_rf_power_level,
      note: "",
      requested_at: null
    });
  };


  redirectToNotificationScreenWithMac(macId) {
    localStorage.setItem('macIdForNotificationFilter', macId);
    window.open("/notifications", "_blank");
  }

  handleOpenShowSnapshots = (id) => {
    this.setState({ openShowSnapshots: true, deviceId: id })
  }

  handleCloseShowSnapshots = () => {
    this.setState({ openShowSnapshots: false, deviceId: null })
  }

  handleOpenDeviceConf(id) {
    this.setState({ openDeviceConf: true, deviceId: id });
  }

  handleCloseDeviceConf = () => {
    this.setState({ openDeviceConf: false, deviceId: null })
  }

  // hover event hide and show the more in BRH device table
  showMoreHandler() {
    this.setState({ moreBtn: true });
  }
  hidehMoreHandler() {
    this.setState({ moreBtn: false });
  }

  closeMorehide() {
    this.setState({ moreBtn: false });
  }

  handleOpenRuntimeConf(requested_at) {
    this.setState({ openRuntimeConf: true, requested_at });
  }

  handleOpenGetSnapshot = (id, requested_at) => {
    this.setState({ openGetSnapshot: true, deviceId: id, requested_at });
  }

  handleCloseGetSnapshot = () => {
    this.setState({ openGetSnapshot: false, deviceId: null, note: '', requested_at: null });
  }

  onChangeTXFreq = (e) => { this.setState({ TXFreq: e.target.value }) }

  onChangeTXFreq8m = (e) => { this.setState({ TXFreq8m: e.target.value }) }

  onChangeTXPower = (e) => this.setState({ TXPower: e.target.value })

  onChangeTXPaControl = (e) => { this.setState({ TXPaControl: e.target.value }) }

  onClickUpdateRuntimeConf = (selectedDevice) => {
    let deviceMacId = selectedDevice.provisioned_device.mac_address;
    var selected_device_configuration = this.props.configurationsInfo;
    let configdata = {
      tx_frequency: this.state.TXFreq,
      tx_power: this.state.TXPower,
    };

    if (selectedDevice.device_model_version >= "1.5" &&
      selected_device_configuration.transition_frequency === true) {
      configdata['tx_frequency'] = this.state.TXFreq8m
    }

    // All Monkey Business Below to make sure We support Device Downgraded and upgraded
    if (selectedDevice.device_model_version < "1.1") {
      // For old devices we just set it to true as will be required when they are upgraded
      // Setting this to null causes an exception SW-3040
      configdata['tx_pa_control'] = true
    } else {
      if (this.state.TXPaControl === null) {
        configdata['tx_pa_control'] = true
      } else {
        configdata['tx_pa_control'] = this.state.TXPaControl
      }
    }

    const data = this.state.note ? { ...configdata, note: this.state.note } : configdata;
    doRuntimeConfigUpdate(
      deviceMacId,
      data,
      (response) => {
        toast("Runtime Config Update Requested.", {
          autoClose: 5000,
          type: toast.TYPE.INFO,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        //this.handleCloseRuntimeConf(selectedDevice)
        this.setState({
          ...this.state,
          openRuntimeConf: false,
        });
      },
      (error) => {
        toast("Runtime Config Update Request Failed.", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_LEFT,
        });
        this.setState({
          ...this.state,
          openRuntimeConf: false,
        });
      }
    );
  }

  openRebootShutdownAlert = (id, requested_at, shutdown = false) => {
    this.setState({
      openAlert: true,
      requestShutdown: shutdown,
      deviceId: id,
      requested_at
    })
  }

  closeRebootAlert = () => {
    this.setState({
      openAlert: false,
      requestShutdown: false,
      deviceId: null,
      swap: false,
      requested_at: null,
      note: '',
    })
  }

  rebootSwapChange = (e) => {
    this.setState({
      swap: e.target.checked
    })
  }

  setFilterURL = (value) => {
    this.setState({
      ...this.state,
      filterURL: value
    })
  }

  handleChangeNote = (e) => {
    this.setState({ note: e.target.value })
  }

  getContentView() {
    const brhdevices = this.props.brhDevicesInfo.sort(
      (a, b) => {
        if (b.device_state > a.device_state)
          return 1;
        if (b.device_state < a.device_state)
          return -1;
        if (b.device_created_at > a.device_created_at)
          return 1;
        if (b.device_created_at < a.device_created_at)
          return -1;
        return 0;
      });
    var placeholder = "Search here ";
    var selectedDevice = brhdevices.find(row => { return row.id === this.state.selected });
    var class_red = (!this.state.lastDeviceStats ? "red" : "");
    const selected_serial_no = (selectedDevice ? selectedDevice.provisioned_device.serial_no : "Unknown");
    const selected_device_model_no = (selectedDevice ? selectedDevice.provisioned_device.model_no : "Unknown");
    const role = localStorage.getItem("roleKey");
    var selected_device_configuration = this.props.configurationsInfo;
    var selectedDevice_stl_source = (selected_device_configuration.stl_source)

    const devicesToUpgrade = brhdevices.length ?
      brhdevices.filter((device) => device.device_state === 3 &&
        Boolean(device.device_upgrade_requested) === false &&
        device.provisioned_device.model_no === this.state.selectedModelForFwUpgrade) : [];

    return (
      <div className="content-wrapper">

        <div className="content">

          {/* Device Model Selector for Upgrade Many*/}
          {this.state.isModelSelectorDialogOpen &&
            <ListSelectorDialog
              open={this.state.isModelSelectorDialogOpen}
              onClose={this.closeModelSelectorDialogForFWUpgrade}
              items={BRHModels}
              title={'Select Device Model'}
              selectedValue={BRHModels[1]}
            />
          }

          {/* Geo Location Dialog*/}
          {this.state.openGeoPopUp ?
            <GeolocationModal
              open={true}
              handleClose={this.handleClose}
              activeDevices={this.props.brhDevicesInfo}
              title={'Geo-Location of the Devices'}
            />
            : ''}

          {this.state.openGeoPopUpSingle && (
            <GeolocationModal
              open={true}
              handleClose={this.handleClose}
              activeDevices={new Array(selectedDevice)}
              title={'Geo-Location of the Device'}
            />
          )}

          {/* Upgrade Many Dialog*/}
          {this.state.openUpgradeMany ?
            <UpgradeModal
              open={this.state.openUpgradeMany}
              handleClose={this.handleClose}
              upgradeMany={true}
              selectedModel={this.state.selectedModelForFwUpgrade}
              activeDevices={devicesToUpgrade}
            />
            : ''}

          {/* Main Devices Table */}
          <div>
            <div className="row main_top_headding">
              <div className="col-md-4 main_top_headding_mid textLeft">
                <ul className="list-inline">
                  <li>
                    <h4><i className="fa fa-laptop" aria-hidden="true"></i>&nbsp;BRH Devices</h4>
                  </li>
                  <li>
                    <PopupState variant="popover" popupId="active-devices-actions" onClick={this.closeMorehide}>
                      {popupState0 => (
                        <React.Fragment>
                          <Button className={"transp "}
                            variant="contained" {...bindTrigger(popupState0)}>
                            <span><i className="fa fa-bars fa-border"></i></span>
                          </Button>
                          <Menu {...bindMenu(popupState0)}>
                            <MenuItem className="item-action"
                              onClick={() => {
                                popupState0.close();
                                this.handleOpenGeolocationModal(false);
                              }}
                            >
                              <i className="fa fa-fw fa-map-marker" aria-hidden="true"></i> &nbsp; Show On Map
                            </MenuItem>
                            <MenuItem className="item-action"
                              onClick={() => {
                                popupState0.close();
                                this.handleOpenUpgradeMultiple();
                              }}
                            >
                              <i className="fa fa-fw fa-laptop" aria-hidden="true"></i> &nbsp; Upgrade Devices
                            </MenuItem>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                    <span className="tooltiptext">Perform Actions on Multiple Active Devices</span>
                  </li>
                </ul>
              </div>
              <div className="col-md-8 main_top_headding_right">
                <div className="tip-tool">
                  <SearchComponent searchAPICall={this.props.searchAPICall}
                    isValidInput={isValidInput}
                    placeHolder={placeholder}
                    defaultSearchString=""
                    url="frontend/devices/?"
                    setFilterURL={this.setFilterURL}
                  />
                  <span className="tooltiptext">  "macid=aa:bb:cc:dd:ee:ff or model=SLBRH40WA/SLBRH40WB/SLBRH05WA" </span>

                </div>
              </div>
            </div>{/*<!-- row ---> */}
            {brhdevices.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <h4>No Devices Found</h4>
              </div>
            ) : (
              <div className="row brhdevices_table">
                <Paper>
                  <TableContainer className="table-all table-responsive table-resizable ">
                    <Table stickyHeader id="myTable">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" className="text-wrap-head text-center" >Status</TableCell>
                          <TableCell align="center" className="text-wrap-head">Serial No.</TableCell>
                          <TableCell align="center" className="text-wrap-head">Mac ID</TableCell>
                          <TableCell align="center" className="text-wrap-head">Reboots</TableCell>
                          <TableCell align="center" className="text-wrap-head">Location</TableCell>
                          <TableCell align="center" className="text-wrap-head">Active Version</TableCell>
                          <TableCell align="center">Standby Version</TableCell>
                          <TableCell align="center" className="text-wrap-head">Configuration</TableCell>
                          <TableCell align="center" className="text-wrap-head">Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {brhdevices.length > 0 && brhdevices.map(row => (
                          <TableRow className="more-option tableRowClickable" onMouseEnter={this.showMoreHandler}
                            onMouseLeave={this.hidehMoreHandler}
                            key={row.id}
                            selected={this.state.selected === row.id}
                            onClick={
                              (this.getDeviceStatisticsOnRowClick) ?
                                () =>
                                  this.getDeviceStatisticsOnRowClick(row.id) : null
                            }
                            classes={{ selected: "table-row-selected" }}
                          >
                            <TableCell align="center" className="text-wrap text-center">
                              {this.props.getStatus(row.device_state)}
                            </TableCell>

                            <TableCell align="center" className="text-wrap">
                              {row.provisioned_device.serial_no} ({row.provisioned_device.model_no})
                            </TableCell>

                            <TableCell align="center" className="text-wrap">
                              {row.provisioned_device.mac_address}
                            </TableCell>

                            <TableCell align="center" className="text-wrap">
                              {row.device_reboot_counts}
                            </TableCell>

                            <TableCell align="center" className="text-wrap">
                              <Button
                                disabled={(parseInt(row.device_longitude) || parseInt(row.device_latitude)) === 0}
                                className={"transp show-more"}
                                onClick={() => { this.handleOpenGeolocationModal(true) }}
                              >
                                {(row.device_latitude === null) ?
                                  "Unknown" :
                                  row.device_latitude.toFixed(4)},{" "}
                                {(row.device_longitude === null) ?
                                  "Unknown" :
                                  row.device_longitude.toFixed(4)}
                              </Button>
                            </TableCell>

                            <TableCell align="center" className="text-wrap">
                              {row.device_active_sw_version}
                            </TableCell>

                            <TableCell align="center" className="text-wrap">
                              {row.device_standby_sw_version}
                            </TableCell>

                            <TableCell align="center" className="text-wrap">
                              <Button
                                className={"transp show-more"}
                                onClick={
                                  () => {
                                    // popupState.close();
                                    this.handleOpenDeviceConf(row.id)
                                  }
                                }
                              >
                                <i
                                  className="fa fa-eye fa-lg"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </TableCell>

                            <TableCell align="center" className="text-wrap">
                              <PopupState variant="popover" popupId="demo-popup-menu" onClick={this.closeMorehide}>
                                {popupState => (
                                  <React.Fragment>
                                    <Button className={"transp show-more"}
                                      variant="contained" {...bindTrigger(popupState)}>
                                      <span><i className="fa fa-ellipsis-v fa-2x"></i></span>
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                      <MenuItem className="item-action"
                                        disabled={row.device_state !== 3 || row.device_reboot_requested !== 0}
                                        onClick={() => {
                                          popupState.close();
                                          this.openRebootShutdownAlert(row.id, row.device_reboot_requested_at);
                                        }}
                                      >
                                        <i className="fa fa-fw fa-power-off" aria-hidden="true"></i> &nbsp; Reboot
                                      </MenuItem>
                                      <MenuItem className="divider item-action"
                                        disabled={row.device_state !== 3 || row.device_shutdown_requested !== 0}
                                        onClick={() => {
                                          popupState.close();
                                          this.openRebootShutdownAlert(row.id, row.device_shutdown_requested_at, true);
                                        }}
                                      >
                                        <i className="fa fa-fw fa-power-off" aria-hidden="true"></i> &nbsp; Shutdown
                                      </MenuItem>
                                      <MenuItem className="item-action"
                                        disabled={row.device_state !== 3 || row.device_upgrade_requested !== 0}
                                        onClick={() => {
                                          popupState.close();
                                          this.handleOpenUpgradeSingle(row.id, row.device_upgrade_requested_at);
                                        }}
                                      >
                                        <i className="fa fa-fw fa-laptop" aria-hidden="true"></i> &nbsp; Upgrade
                                      </MenuItem>
                                      <MenuItem className="item-action"
                                        disabled={row.device_state !== 3 || row.device_runtime_cfg_update_requested !== 0}
                                        onClick={() => {
                                          popupState.close();
                                          this.handleOpenRuntimeConf(row.device_runtime_cfg_update_requested_at);
                                        }}
                                      >
                                        <i className="fa fa-fw fa-cog" aria-hidden="true"></i> &nbsp; Runtime Configuration
                                      </MenuItem>
                                      {(row.device_model_version >= "1.2" && role === "owner") ?
                                        (<MenuItem className="divider item-action"
                                          disabled={row.device_state !== 3 || row.device_snapshot_requested !== 0}
                                          onClick={() => {
                                            popupState.close();
                                            this.handleOpenGetSnapshot(row.id, row.device_snapshot_requested_at);
                                          }}
                                        >
                                          <i className="fa fa-fw fa-camera" aria-hidden="true"></i> &nbsp; Get Snapshot
                                        </MenuItem>
                                        ) :
                                        ""
                                      }
                                      <MenuItem className="item-action"
                                        onClick={
                                          () => {
                                            popupState.close();
                                            this.redirectToNotificationScreenWithMac(row.provisioned_device.mac_address);
                                          }
                                        }
                                      >
                                        <i className="fa fa-fw fa-bell-o" aria-hidden="true"></i> &nbsp; Show Notifications
                                      </MenuItem>
                                      {(row.device_model_version >= "1.2" && role === "owner") ?
                                        (<MenuItem className="item-action"

                                          onClick={
                                            () => {
                                              popupState.close();
                                              this.handleOpenShowSnapshots(row.id)
                                            }
                                          }
                                        >
                                          <i className="fa fa-fw fa-camera" aria-hidden="true"></i> &nbsp; Show Snapshots
                                        </MenuItem>
                                        ) :
                                        ""
                                      }
                                    </Menu>
                                  </React.Fragment>
                                )}
                              </PopupState>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            )}


            {/* Alert for Reboot/Shutdown */}
            <AlertDialogSlide
              open={this.state.openAlert}
              handleClose={this.closeRebootAlert}
              title={(this.state.requestShutdown ? "Shutdown The Device?" : "Reboot The Device?") + ` (${selected_serial_no})`}
              desc={`Are you sure you want to ${this.state.requestShutdown ? "Shutdown" : "Reboot"} the device ${selected_serial_no}?\n`}
              elements={
                <Card>
                  <CardContent>
                    {this.state.requested_at ?
                      <Typography>Last Performed:
                        {(new Date(this.state.requested_at)).toLocaleString()}
                      </Typography>
                      : null}
                    <TextField
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      label="Note (Reason/Cause for Action)"
                      value={this.state.note}
                      onChange={this.handleChangeNote}
                      fullWidth
                      multiline
                      rowsMax={4}
                      margin="normal"
                    />
                    {!this.state.requestShutdown &&
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Swap Active Partition"
                        onChange={this.rebootSwapChange}
                      />
                    }
                  </CardContent>
                </Card>
              }
              action={this.requestDeviceRebootShutdown}
            />

            {/* Alert for Get Snapshot */}
            <AlertDialogSlide
              open={this.state.openGetSnapshot}
              handleClose={this.handleCloseGetSnapshot}
              title={`Get Snapshot (${selected_serial_no})`}
              desc={`Are you sure you want to get snapshot for this device ${selected_serial_no}?\n`}
              action={this.requestSnapshot}
              elements={
                <Card>
                  <CardContent>
                    {this.state.requested_at ?
                      <Typography>Last Performed:
                        {(new Date(this.state.requested_at)).toLocaleString()}
                      </Typography>
                      : null}
                    <TextField
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      label="Note (Reason/Cause for Action)"
                      value={this.state.note}
                      onChange={this.handleChangeNote}
                      fullWidth
                      multiline
                      rowsMax={4}
                      margin="normal"
                    />
                  </CardContent>
                </Card>
              }
            />

            {/* Show Device Configuration Dialog */}
            {this.state.openDeviceConf ?
              <DeviceConfiguration
                open={this.state.openDeviceConf}
                handleClose={this.handleCloseDeviceConf}
                device={selectedDevice}
                Transition={Transition}
              />
              : null}

            {/* Show Snapshots Dialog */}
            {this.state.openShowSnapshots ?
              <Snapshots
                open={this.state.openShowSnapshots}
                handleClose={this.handleCloseShowSnapshots}
                device={selectedDevice}
                Transition={Transition}
              />
              : null}
            {this.state.openUpgradeSingle &&
              <UpgradeModal
                open={this.state.openUpgradeSingle}
                handleClose={this.handleClose}
                selectedSerialNo={selected_serial_no}
                selectedModel={selected_device_model_no}
                requested_at={this.state.requested_at}
                activeDevices={devicesToUpgrade}
                selectedDevice={this.props.brhDevicesInfo.filter(device => device.id === this.state.selected)}
                upgradeMany={false}
              />
            }

            {/* Runtime Configuration Dialog */}

            <div>
              <Dialog
                fullScreen
                open={this.state.openRuntimeConf}
                onClose={() => this.handleCloseRuntimeConf(selectedDevice)}
                TransitionComponent={Transition}
                className="popup-module"
                disableBackdropClick
              >
                <AppBarComponent
                  icon={"fa fa-cog"}
                  title={"Runtime Configuration " +
                    (selectedDevice ? `(${selectedDevice.provisioned_device.serial_no})` : null)}
                  handleClose={() => this.handleCloseRuntimeConf(selectedDevice)}
                />

                <DialogContent className="dialogContent">
                  <div className="col-md-4 col-sm-0"></div>
                  <div className="col-md-4 col-sm-12">
                    <form className="dialogContent-form">
                      <p className="phead"> <b>Configuration Update</b> </p>
                      <Card>
                        <CardContent>

                          {this.state.requested_at ?
                            <Typography>Last Performed:
                              {(new Date(this.state.requested_at)).toLocaleString()}
                            </Typography>
                            : null}
                          <TextField
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            label="Note (Reason/Cause for Action)"
                            value={this.state.note}
                            onChange={this.handleChangeNote}
                            fullWidth
                            multiline
                            rowsMax={4}
                            margin="normal"
                          />

                          <TextField
                            select
                            label="Tx Power"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={this.state.TXPower}
                            onChange={(e) => this.onChangeTXPower(e)}
                          >
                            {selected_device_model_no !== BRHModels[2] ? (
                              RfPowerLevels.map(row => (
                                <option key={row.key} value={row.value}>{row.key}</option>
                              ))
                            ) :
                              RfPowerLevels5w.map(row => (
                                <option key={row.key} value={row.value}>{row.key}</option>
                              ))
                            }
                          </TextField>
                          {selected_device_configuration.transition_frequency === true && selectedDevice.device_model_version >= "1.5" ? (
                            <TextField
                              select
                              label="Transmission Frequency (MHz)"
                              style={{ marginBottom: 10 }}
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={this.state.TXFreq8m}
                              onChange={(e) => this.onChangeTXFreq8m(e)}
                            >
                              {transmitFrequencies8M.map(row => (
                                <option key={row.key} value={row.value}>{row.key}</option>
                              ))}
                            </TextField>
                          )
                            : <TextField
                              select
                              label="Transmission Frequency (MHz)"
                              style={{ marginBottom: 10 }}
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={this.state.TXFreq}
                              onChange={(e) => this.onChangeTXFreq(e)}
                            >
                              {selected_device_model_no === BRHModels[2] ? (
                                transmitFrequencies5w.map(row => (
                                  <option key={row.key} value={row.value}>{row.key}</option>
                                ))
                              ) :
                                transmitFrequencies6M.map(row => (
                                  <option key={row.key} value={row.value}>{row.key}</option>
                                ))
                              }
                            </TextField>
                          }

                          {selectedDevice && selectedDevice.device_model_version >= "1.1" ? (
                            <TextField
                              select
                              label="RF Transmission"
                              style={{ marginBottom: 10 }}
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={this.state.TXPaControl}
                              onChange={(e) => this.onChangeTXPaControl(e)}
                            >
                              {paControl.map(row => (
                                <option key={row.key} value={row.value}>{row.key}</option>
                              ))}
                            </TextField>
                          ) : null
                          }
                          <br />
                          <div className="form-button-container">
                            <Button variant="outlined"
                              className="form-submit-button"
                              onClick={() => this.onClickUpdateRuntimeConf(selectedDevice)}
                            > Update Configuration
                            </Button>
                          </div>
                        </CardContent>
                      </Card>
                    </form>
                    <br />
                    <div style={{ border: "2px solid red", padding: "10px" }}>
                      <label>Note: Request for change in Transmission frequency will result in RF downtime of a few seconds</label>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-0">
                  </div>
                </DialogContent>
              </Dialog>
            </div>



            {(brhdevices.length > 0) ? (
              <Paper style={{ marginTop: 10 }}>
                <div className="row brhdevices_table">
                  <div className="row">
                    <div className="col-sm-6">
                      <h4 className={"statistics " + class_red}>
                        Statistics {selectedDevice ?
                          ` (${selectedDevice.provisioned_device.serial_no}) -  ${selectedDevice.provisioned_device.model_no}`
                          : null}
                      </h4>
                      <SimpleExpansionPanel
                        lastDeviceStats={this.state.lastDeviceStats}
                        selectedDevice={selectedDevice}
                        selected_device_configuration={selected_device_configuration}
                      />

                    </div>{/*----- -----*/}
                    <div className="col-sm-6">
                      <h4>Real Time Data</h4>
                      <Modal
                        buttonName="Plot Historical Chart"
                        disable={this.props.brhDevicesInfo.filter(device => device.id === this.state.selected).length === 0}
                        data={
                          <HistoricalPlot
                            selectedDevice={this.props.brhDevicesInfo.filter(device => device.id === this.state.selected)}
                            defaultValue={this.state.defaultValue}
                            deviceStats={this.state.deviceStats}
                            deviceSource={selectedDevice_stl_source}
                          />
                        }
                      />
                      <Options
                        getSelected={this.selectedValuesChanged}
                        defaultValue={this.state.defaultValue}
                        deviceSource={selectedDevice_stl_source}
                      />
                      {this.state.selectHelperText}
                      {(this.state.selectedKeys.length < 2) ?
                        (<div className="row"> </div>) :
                        (this.state.graphData.length > 0) ? (
                          <div className="row">
                            <Chart
                              // width={'100%'}
                              height={'500px'}
                              chartType="LineChart"
                              legendToggle
                              loader={<div>Loading Chart</div>}
                              data={this.state.graphData}
                              options={{
                                // height: '600px',
                                chartArea: { width: '90%', height: '80%' },

                                legend: 'bottom',
                                hAxis: {
                                  textPosition: 'none',
                                },
                                vAxis: {
                                  textPosition: "none"
                                },
                                curveType: 'function',
                                pointSize: '8',
                              }}
                              rootProps={{ 'data-testid': '2' }}
                            />
                          </div>) : null}
                    </div>
                  </div>{/*---- Statistics-----*/}
                </div>
              </Paper>
            ) : (<div />)}

          </div>

        </div>



      </div>
    );
  }

  render() {
    return (
      <ContentWrapper
        contentView={this.getContentView()}
        netError={this.props.netError}
      />
    );
  }
}

// //Required to tell redux how this component is mapped with dashboard reducers
function mapStateToProps(state) {
  return {
    brhDevicesInfo: state.brhDevicesInfo.brhDevicesInfo,
    statisticsInfo: state.brhDevicesInfo.statisticsInfo,
    configurationsInfo: state.configurationsInfo.configurationsInfo,
    action: state.brhDevicesInfo.action,
    netError: state.brhDevicesInfo.netError
  }
}

//Required to tell redux this component using which actions
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeSidebarButtonState,
    fetchBRHDevicesData,
    getConfigurationData,
    getStatus,
    getDeviceStatistics,
    searchAPICall,
    isValidInput
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BRHDeviceComponent);
